import helpers from './helpers';
export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL';
export const LOGIN_FAILED = 'LOGIN_FAILED'; 
export const LOGOUT_REQUESTED = 'LOGOUT_REQUESTED';
export const REFRESH_REQUESTED = 'REFRESH_REQUESTED';
export const REFRESH_SUCCESSFUL = 'REFRESH_SUCCESSFUL';
export const REFRESH_FAILED = 'REFRESH_FAILED'; 
export const UNAUTHORIZED_RESPONSE = 'UNAUTHORIZED_RESPONSE';


function loginSuccessful(response) {
  return {
    type: LOGIN_SUCCESSFUL,
    response
  }
}

function refreshSuccessful(response) {
  return {
    type: REFRESH_SUCCESSFUL,
    response
  }
}

export function unauthorized(){
  return {
    type: UNAUTHORIZED_RESPONSE,
  }
}


export function refresh() {
  return (dispatch) => {
    dispatch({ type: REFRESH_REQUESTED})
    helpers.get('/api/auth/refresh')
      .then( response => {
        dispatch(refreshSuccessful(response))
      }).catch( error => {
        dispatch({ type: REFRESH_FAILED })
      })
  }
}

export function login(email, password) {
  return (dispatch) => {
    dispatch({ type: LOGIN_REQUESTED})
    helpers.post('/api/auth/login', {email, password})
      .then( response => {
        dispatch(loginSuccessful(response))
      }).catch( error => {
        dispatch({ type: LOGIN_FAILED })
      })
  }
}

export function logout() {
  return (dispatch) => {
    dispatch({ type: LOGOUT_REQUESTED})
    helpers.post('/api/auth/logout').catch(e=>{})
  }
}
