import React from 'react';
import { useState, useEffect } from 'react';
import {useParams} from 'react-router-dom';
import {
  Container,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography, 
  makeStyles,
} from '@material-ui/core';
import helpers from '../actions/helpers';
import {Button} from '@material-ui/core';
import {useStyles} from '../styles';

const processUser = (user) => {
    let mostRecent = new Date(null);
    if (!user.sessions) {
      user.sessions = [];
    }
    for(let session of user.sessions) {
      let curDate = new Date(session.updated_at)
      if (curDate > mostRecent) {
        mostRecent = curDate;
      }
      user.updatedAt = curDate.toLocaleDateString()
    }
  return user
}


export default function Users(){
  const [ user, setUser ] = useState({})
  const classes = useStyles()
  const {uuid} = useParams();

  useEffect(()=>{
    helpers.get('api/users/' + uuid)
      .then( user => {
        setUser(processUser(user))
      }).catch( error => {
        console.error(error)
      })
  }, [])

  const deleteUser = (uuid)=>{
    helpers.deleteReq("api/users/" + uuid)
  }
  return <Container>
    <Typography className={classes.heading} variant="h5" component="h2">
      User {user.email}
    </Typography>
    <Paper className={classes.root}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Session Number</TableCell>
            <TableCell>IPs</TableCell>
            <TableCell>Updated At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {user?.sessions?.map((session,i) => (
            <TableRow
              hover
              key={i}>
              <TableCell scope="row">
                {i}
              </TableCell>
              <TableCell scope="row">
                {session.ips.join(", ")}
              </TableCell>
              <TableCell scope="row">
                {user.updatedAt}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
    <Button onClick={()=>deleteUser(uuid)}variant="contained" color="secondary">
      Delete
    </Button>
  </Container>
}
