import React from 'react';
import { useState } from 'react';
import { LinearProgress, Paper, makeStyles, InputBase, IconButton } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(3, 0, 0, 0),
    display: "flex",
  },
  progress: {
  },
  input: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));


export default function SearchBar(props) {
  const classes = useStyles()
  const [ query, setQuery ] = useState("");

  const onSubmit = e => {
    e.preventDefault()
    if(!query){
      return
    }
    props.onSubmit(query)
  }

  const onChange = e => {
    e.preventDefault()
    setQuery(e.target.value.trim())
  }

  return (
    <div>
      <Paper component="form" className={classes.root} onSubmit={onSubmit}>
        <InputBase
          onChange={onChange}
          className={classes.input}
          placeholder={props.placeholder}
          type="text"
        />
        <IconButton type="submit" className={classes.iconButton} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>
      { props.loading ? <LinearProgress className={classes.progress} /> : null }
    </div>);
}
