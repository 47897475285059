import { createStore, applyMiddleware, compose as reduxCompose} from "redux";
import rootReducer from "./reducers";
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension';


const compose = process.env.NODE_ENV === 'development' ? composeWithDevTools : reduxCompose;
const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunkMiddleware),
  )
);

window.store = store 

export default store

