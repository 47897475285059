import {
  LOGIN_REQUESTED ,
  LOGIN_SUCCESSFUL,
  LOGIN_FAILED,
  LOGOUT_REQUESTED ,
  REFRESH_REQUESTED,
  REFRESH_SUCCESSFUL,
  REFRESH_FAILED,
  UNAUTHORIZED_RESPONSE,
} from '../actions/auth';

const initialState = {
  initialRefresh: true,
  authenticated: false,
  authInProgress: false,
  authFailed: false,
  isAdmin: false,
}

export default function reducer(state=initialState, action) {
  const newState = {}
  switch(action.type) {
    case LOGIN_REQUESTED:
    case REFRESH_REQUESTED:
      newState.authInProgress = true
      break
    case LOGIN_SUCCESSFUL:
    case REFRESH_SUCCESSFUL:
      newState.isAdmin = action.response.is_admin
      newState.initialRefresh = false
      newState.authenticated = true
      break
    case LOGIN_FAILED:
      newState.authFailed = true
      newState.initialRefresh = false
      newState.authenticated = false
      break
    case REFRESH_FAILED:
      newState.initialRefresh = false
      newState.authenticated = false
      break
    case LOGOUT_REQUESTED:
    case UNAUTHORIZED_RESPONSE:
      newState.initialRefresh = false
      newState.authenticated = false
      break
    default:
      return state
  }
  return {...state, ...newState}
}
