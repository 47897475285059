import './App.css';

import React, { useState } from 'react';
import { Wait, AppBar, PrivateRoute, AdminRoute } from './components';
import { drawerWidth } from './components/Sidebar';
import { Search, Login, Users, User } from './pages';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { createTheme, makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import store from './store';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { refresh } from './actions/auth';

function inDarkMode(){
  return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
}

function themeSpec(){
  return {
    palette: {
      type: inDarkMode() ? 'dark' : 'light',
      primary: { main: "#0d47a1" },
      secondary: { main: "#c62828"},
    }
  }
};

const useStyles = makeStyles(theme => ({
  base: {
    color: theme.palette.primary.main
  },
  root: {
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  contentAppbar: {
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      marginLeft: drawerWidth
    },
  }
}));

function App() {
  var classes = useStyles()
  var user = useSelector(store => store.user);
  var appClass = user.authenticated ? classes.contentAppbar : classes.content 
  var [theme, setTheme]  = useState(createTheme(themeSpec()));

  // switch to dark/light mode automatically
  window.matchMedia('(prefers-color-scheme: dark)')
    .addListener(event => {
      setTheme(createTheme(themeSpec()));
    });

  return (
    <div>
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <Wait show={user.initialRefresh === false}>
          <Router>
            <AppBar sidebar={user.authenticated}/>
            <main className={appClass}>
              <div className={classes.toolbar} />
              <Switch>
                <Route path="/login">
                  <Login />
                </Route>
                <AdminRoute path="/users/:uuid">
                  <User />
                </AdminRoute>
                <AdminRoute path="/users">
                  <Users />
                </AdminRoute>
                <PrivateRoute path="/">
                  <Search />
                </PrivateRoute>
              </Switch>
            </main>
          </Router>
        </Wait>
      </ThemeProvider>
    </div>
  );
}

store.dispatch( refresh() );

export default App;
