import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  heading: {
    margin: theme.spacing(3, 0, 2, 0),
  },
}));
