import React from 'react';
import { useState, useEffect } from 'react';
import {useHistory} from 'react-router-dom';
import {
  Container,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography, 
  makeStyles,
} from '@material-ui/core';
import helpers from '../actions/helpers';
import {useStyles} from '../styles'

const processUsers = (users) => {
  for (var i = 0; i < users.length; i++) {
    let user = users[i];
    let mostRecent = new Date(null);
    if (!user.sessions) {
      user.sessions = [];
    }
    for(let session of user.sessions) {
      let curDate = new Date(session.updated_at)
      if (curDate > mostRecent) {
        mostRecent = curDate;
      }
      user.updatedAt = curDate.toLocaleDateString()
    }
  }
  return users
}


export default function Users(){
  const [ userList, setUserList ] = useState([])
  const classes = useStyles()
  const history = useHistory()

  useEffect(()=>{
    helpers.get('/api/users')
      .then( users => {
        setUserList(processUsers(users))
      }).catch( error => {
        console.error(error)
      })
  }, [])
  return <Container>
    <Typography className={classes.heading} variant="h5" component="h2">
      Users
    </Typography>
    <Paper className={classes.root}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Sessions</TableCell>
            <TableCell>Updated At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userList.map(user => (
            <TableRow
              hover
              onClick={ _ => history.push("/users/" + user.uuid)}
              key={user.uuid}>
              <TableCell scope="row">
                {user.email}
              </TableCell>
              <TableCell scope="row">
                {user.sessions.length}
              </TableCell>
              <TableCell scope="row">
                {user.updatedAt}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  </Container>
}
