import helpers from './helpers';
export const TRANSFER_REQUESTED = 'TRANSFER_REQUESTED';
export const TRANSFER_SUCCESSFUL = 'TRANSFER_SUCCESSFUL';
export const TRANSFER_FAILED = 'TRANSFER_FAILED'; 

function transferRequested(link){
  return { type: TRANSFER_REQUESTED, link }
}


function transferSuccessful(link){
  return { type: TRANSFER_SUCCESSFUL, link }
}

function transferFailed(link){
  return { type: TRANSFER_FAILED, link }
}
export function startTransferRedux(url) {
  return (dispatch) => {
    dispatch(transferRequested(url))
    helpers.post('/api/media', {url})
      .then( results => {
        dispatch(transferSuccessful(url))
      }).catch( error => {
        debugger
        dispatch(transferFailed(url))
      })
  }
}

export function startTransfer(url, successCallback, errorCallback) {
  helpers.post('/api/media', {url})
    .then( result => {
      successCallback(result)
    }).catch( error => {
      errorCallback()
    })
}
