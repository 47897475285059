import React from 'react';


export function Wait(props){
  if(props.show){
    var renderProps = {...props, show:null}
    return <div {...renderProps} />
  } 
  return <div />
}

export default Wait;
