import React from 'react';
import { useState } from 'react';
import { SearchBar, Wait } from '../components';
import {
  Container, 
  Checkbox, 
  Fab,
  Paper, 
  Typography, 
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableFooter,
  TablePagination,
  TableCell,
  makeStyles} from '@material-ui/core';
import { GetApp as DownloadIcon } from '@material-ui/icons';
import { startTransfer } from '../actions/media';
import helpers from '../actions/helpers';

const ROWS_PER_PAGE = 5;

function search(query, successCallback, errorCallback) {
    helpers.get('/api/search', {query})
    .then( results => {
      successCallback(results)
    }).catch( error => {
      errorCallback(error)
    })
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    margin: theme.spacing(3, 0),
  },
  heading: {
    margin: theme.spacing(3, 0, 2, 0),
  },
  tail: {
    margin: theme.spacing(4,0)
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  }
}));

function resultCheckbox(result, resultStates) {
  let resultState = resultStates[result.link]
  if(resultState.selected || (!resultState.pending && !resultState.completed) ) {
    return <Checkbox
      checked={resultState.selected}
    />
  } else if (resultState.pending) {
    return <span role="img" aria-label="In progress">🟨</span>
  } else if (resultState.successful) {
    return <span role="img" aria-label="Completed">✅</span>
  } else {
    return <span role="img" aria-label="Failed">🟥</span>
  }
}

function newResultStates(results){
  let states = {}
  for (let result of results) {
    states[result.link] = {
      selected: false,
      pending: false,
      failed: false,
      completed: false,
    }
  }
  return states
}


export default function Search() {
  const classes = useStyles();
  const [ page, setPage ] = useState(0);
  const [ loading, setLoading ] = useState(false)
  const [ results, setResults ] = useState([])
  const [ resultStates, setResultStates ] = useState({})

  const handleClick = (e, link) => {
    var newStates = {...resultStates}
    newStates[link].selected = !resultStates[link].selected
    setResultStates(newStates)
  }

  const performSearch = query => {
    setPage(0)
    setLoading(true)
    setResults([])
    search(query, (newResults)=>{
      setLoading(false)
      setResultStates(newResultStates(newResults))
      setResults(newResults)
    }, ()=>{
      setLoading(false)
      setResultStates({})
      setResults([])
    })
  }


  const startTransfers = () => {
    var links = Object.keys(resultStates)
    let newStates = {...resultStates}
    for(let link of links) {
      if (!resultStates[link].selected) {
        continue
      }
      startTransfer(link, (result) => {
        let newStates = {...resultStates}
        newStates[link].pending = false
        newStates[link].completed = true
        newStates[link].successful = true
        setResultStates(newStates)
      }, () => {
        let newStates = {...resultStates}
        newStates[link].pending = false
        setResultStates(newStates)
      })
      newStates[link].selected = false
      newStates[link].pending = true
    }

    setResultStates(newStates)
  }

  let selectedCount = 0;
  results.forEach(result => {
    if(resultStates[result.link].selected) {
      selectedCount++;
    }
  })

  return <Container>
    <Typography className={classes.heading} variant="h5" component="h2">
      Search
    </Typography>
    <SearchBar 
      loading={loading} 
      placeholder="Search" 
      onSubmit={performSearch} />
    <Wait show={results.length === 0}>
      <Typography className={classes.heading} variant="h5" component="h2">
        No results.
      </Typography>
    </Wait>
    <Wait show={results.length > 0}>
      <Paper className={classes.root}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Name</TableCell>
              <TableCell align="right">Availability</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.slice(page * ROWS_PER_PAGE, page * ROWS_PER_PAGE + ROWS_PER_PAGE).map(result => (
              <TableRow 
                hover 
                role="checkbox" 
                selected={resultStates[result.link].selected} 
                onClick={ e => handleClick(e, result.link) }
                key={result.link}>
                <TableCell padding="checkbox">
                  { resultCheckbox(result, resultStates) }
                </TableCell>
                <TableCell scope="row">
                  {result.name}
                </TableCell>
                <TableCell align="right">{result.availability}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination 
                count={results.length}
                page={page}
                onChangePage={ (e,newPage) => setPage(newPage) }
                rowsPerPage={ROWS_PER_PAGE}
                rowsPerPageOptions={[]}/>
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    </Wait>
    <Fab color="primary"
      disabled={selectedCount === 0} 
      className={classes.fab} aria-label="Download"
      onClick={startTransfers}>
      <DownloadIcon />
    </Fab>
    <div className={classes.tail} />
  </Container>
}
