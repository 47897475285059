import {unauthorized} from './auth';
function get(urlStr, query, options){
  const url = new URL(urlStr, window.location.origin)
  url.search = new URLSearchParams(query).toString()
  return doFetch(url, options)
}

function post(url, body, options={}){
  options.method = 'POST';
  options.body = JSON.stringify(body)
  return doFetch(url, options)
}

function deleteReq(urlStr, options={}){
  options.method = 'DELETE';
  const url = new URL(urlStr, window.location.origin)
  return fetch(url, options)
         .then(response => {
           if(response.status === 401){
             window.store.dispatch(unauthorized())
           }
           if (!response.ok) {
             throw Error(response.statusText)
           }
           return response;
         })
}


function doFetch(urlStr, ...args){
  const url = new URL(urlStr, window.location.origin)
  return fetch(url, ...args)
         .then(response => {
           if(response.status === 401){
             window.store.dispatch(unauthorized())
           }
           if (!response.ok) {
             throw Error(response.statusText)
           }
           return response;
         })
         .then(response => response.json())
}

export default {get, post, deleteReq}
