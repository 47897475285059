import React from 'react';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Wait from './Wait';
import { logout } from '../actions/auth';
import Sidebar from './Sidebar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  }, 
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  title: {
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(2),
    },
  },
}));

/*
  <IconButton onClick={props.onExpand} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
    <MenuIcon />
  </IconButton>
  */

export default function AppBar(props){
  const classes = useStyles();
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const onLogout = () => {
    dispatch( logout() )
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  var user = useSelector(store => store.user);
  return (
    <div>
      <Wait show={props.sidebar}>
        <Sidebar open={mobileOpen} onClose={handleDrawerToggle}/>
      </Wait>
      <MuiAppBar className={classes.appBar} >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            matj.co
          </Typography>
          <Wait show={user.authenticated === false}>
            <Button color="inherit" to="/login">Login</Button>
          </Wait>
          <Wait show={user.authenticated === true}>
            <Button onClick={onLogout} color="inherit">Logout</Button>
          </Wait>
        </Toolbar>
      </MuiAppBar>
    </div>
  );
}
